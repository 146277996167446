<app-dialog-container>
  <ng-container title>
    <h4 class="text-center">Which program are you interested in?</h4>
  </ng-container>
  <ng-container content>
    <div class="dialog-btn-align">
      @for (item of medicationTypes; track $index) {
      <button
        class="app-button btn-lg btn-outlined"
        (click)="startMedication(item.type)"
      >
        {{ item.title }}
      </button>
      }
    </div>
  </ng-container>
</app-dialog-container>
