import { Component, inject } from '@angular/core';
import { DialogContainerComponent } from '../../general/dialog-container/dialog-container.component';
import { ProductApiService } from 'src/app/core/services/product-api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { GeneticsQuizDialogComponent } from 'src/app/core/components/genetics-quiz-dialog/genetics-quiz-dialog.component';

@Component({
  selector: 'app-start-medication',
  imports: [DialogContainerComponent],
  templateUrl: './start-medication.component.html',
  styleUrl: './start-medication.component.scss'
})
export class StartMedicationComponent {
  private productApiService = inject(ProductApiService);
  medicationTypes = [
    {
      title: 'Weight Loss',
      type: 'survey'
    },
    {
      title: 'Anti-Aging',
      type: 'anti-aging'
    },
    {
      title: 'Peptides',
      type: 'metabolic'
    },
    {
      title: 'ED',
      type: 'ed'
    }
  ];
  constructor(public dialogRef: MatDialogRef<GeneticsQuizDialogComponent>) {}

  startMedication(type: string) {
    this.dialogRef.close();
    this.productApiService.redirectToPage(type);
  }
}
