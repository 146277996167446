import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, retry } from 'rxjs/operators';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import {
  forbiddenSystemStates,
  ORDER_STORAGE_KEY,
  pricesHardcode,
  QUIZ_LINK
} from '../constants/config';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { saveAs } from 'file-saver';
import { LocalstorageService } from './localstorage.service';
import { PriceItem } from '../models/models';
import {
  ANTI_AGING_QUIZ_TYPE,
  ED_FOLLOWUP_QUIZ_TYPE,
  ED_QUIZ_TYPE,
  GENETICS_QUIZ_TYPE,
  METABOLIC_QUIZ_TYPE,
  SEM_12_KICKSTART_MED_ID,
  STARTED_TWO_MONTH_DOSE_ID,
  TER_12_KICKSTART_MED_ID,
  TIER_12_KICKSTAR_PRICE_TYPE,
  WEIGHTLOSS_QUIZ_TYPE
} from 'src/app/pages/survey/quiz/quiz';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { CityDialogComponent } from '../components/header/city-dialog/city-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ViewportScroller } from '@angular/common';
import { StartMedicationComponent } from 'src/app/shared/components/ui/start-medication/start-medication.component';
import { medicationDoseOptions } from 'src/app/pages/survey/quiz/weightloss.quiz';

export interface DosePrice {
  price: PriceItem | null;
  doses: number;
  type: number;
  nextQuantity?: number;
  currentQuantity?: number;
  subscription?: any;
  surcharge?: any;
}

@Injectable({
  providedIn: 'root'
})
export class ProductApiService {
  private http = inject(HttpClient);
  private apiService = inject(ApiService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private localStorage = inject(LocalstorageService);
  private dialogRef = inject(MatDialog);
  private scroller = inject(ViewportScroller);

  // CATEGORIES
  getCategoryBySlug(slug: string): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/product/category/${slug}`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // PRODUCTS
  getProducts(filtration: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/product/all`, filtration)
      .pipe(catchError(this.apiService.handleError()));
  }

  // PRODUCT DETAIL
  getProductBySlug(slug: string): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/product/${slug}`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // PRODUCTS
  getEdProductStart(): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/user/checkIsEDOrderFollowUp`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // ADD TO CART
  getSavedData() {
    return this.localStorage.getItem(ORDER_STORAGE_KEY)
      ? JSON.parse(this.localStorage.getItem(ORDER_STORAGE_KEY) || '{}')
      : {
          steps: {},
          product: {},
          plan: {},
          checkout_step: {},
          registration_step: {},
          type: null,
          order_id: null
        };
  }

  clearData() {
    if (this.localStorage.getItem(ORDER_STORAGE_KEY)) {
      this.localStorage.removeItem(ORDER_STORAGE_KEY);
    }
  }

  setData(data: any) {
    this.localStorage.setItem(ORDER_STORAGE_KEY, JSON.stringify(data));
  }

  formatingQuizData() {
    const data = this.getSavedData();
    const gender = data?.registration_step?.sex;
    if (gender === 'male') {
      if (
        data.type === METABOLIC_QUIZ_TYPE ||
        data.type === ANTI_AGING_QUIZ_TYPE ||
        data.type === WEIGHTLOSS_QUIZ_TYPE
      ) {
        delete data.steps[8];
        delete data.steps[7];
      }
    }
    if (!data.userEdit) {
      data.dose_id = data.product.price.med_id;
      if (data.type === WEIGHTLOSS_QUIZ_TYPE) {
        if (data.steps[202] || data.steps[203]) {
          let values: any;
          if (data.steps[205]) {
            values = data.steps[205]?.values;
          } else {
            values = data.steps[202]?.values || data.steps[203]?.values;
          }
          data.steps[220] = {
            title:
              'Which medication and dose most closely matches your most recent dose?',
            options: medicationDoseOptions,
            values: [
              {
                id: 1,
                title: `${
                  data.product.doses === 2 ? 'Tirzepatide' : 'Semaglutide'
                } ${values[0]?.quantity}mg`
              }
            ]
          };
          if (data.steps[202]) {
            delete data.steps[202];
          }
          if (data.steps[203]) {
            delete data.steps[203];
          }
        } else {
          delete data.steps[220];
        }
      }
    }
    return data;
  }

  // SAVE QUIZ FOR USER
  saveQuiz(): Observable<any> {
    const data = this.formatingQuizData();
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/user/saveQuiz`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // SAVE QUIZ BY SALES
  saveQuizBySales(): Observable<any> {
    const data = this.formatingQuizData();
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/user/saveQuizSeller`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // SAVE QUIZ FOLLOWUP
  saveFollowUpQuiz(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/user/saveFollowUpQuiz`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // QUIZ IS FOR WEIGHTLOSS
  isWeightLossQuiz(data: any): any {
    return data.type === WEIGHTLOSS_QUIZ_TYPE ? true : false;
  }

  // CALCULATE FITNESS QUIZ USER POINS
  fitnessPoints(steps: any): any {
    let points = 0;
    Object.values(steps).map((item: any) => {
      points += item?.values[0]?.point ? item.values[0].point : 0;
    });
    return points;
  }

  // USER ALREADY TAKE WEIGHT LOSS
  isWeightLossPatient() {
    const stepSavedData = this.getSavedData();
    if (this.isWeightLossQuiz(stepSavedData)) {
      return Object.values(stepSavedData.steps).some(
        (item: any) =>
          item?.values?.length && item.values[0]?.weight_loss_patient
      );
    }
    return null;
  }

  // GET SELECTED FOLLOWUP DOSE
  getSelectedStepFolloupDose() {
    const stepSavedData = this.getSavedData();
    const doseSelected =
      stepSavedData.steps[204] && stepSavedData.steps[204]?.values?.length
        ? stepSavedData.steps[204]?.values[0]
        : null;
    if (
      doseSelected &&
      doseSelected?.id === 4 &&
      stepSavedData.steps[doseSelected?.step_id]
    ) {
      if (stepSavedData.steps[doseSelected?.step_id]?.values?.length) {
        return stepSavedData.steps[doseSelected?.step_id]?.values[0];
      }
    }
    return null;
  }

  // FORMAT PRODUCT FOLLOUP
  formatFolloupOrderProduct(order: any, withPayment = false, withAdmin = 0) {
    const stepSavedData = this.getSavedData();
    const medId = order?.last_order?.dose_id
      ? order?.last_order?.dose_id
      : order?.dose_id;
    const product = order?.last_order?.products?.length
      ? order?.last_order?.products[0]
      : order.products[0];
    // FAP1

    // const priceHard = pricesHardcode.find((item: any) => item.type === 11);
    // console.log(priceHard);
    // stepSavedData.product = {
    //   title: product?.name,
    //   id: product?.product_id,
    //   doses: product?.doses,
    //   with_payment: withPayment,
    //   price: {
    //     price: priceHard?.price,
    //     type: priceHard?.type,
    //     med_id: priceHard?.med_id
    //   }
    // };
    // stepSavedData.dose_id = priceHard?.med_id;
    stepSavedData.product = {
      title: product?.name,
      id: product?.product_id,
      doses: product?.doses,
      with_payment: withPayment,
      price: {
        price: product?.price,
        type: product?.price_type,
        med_id: medId
      }
    };
    stepSavedData.dose_id = medId;
    stepSavedData.afu = withAdmin || 0;
    this.setData(stepSavedData);
  }

  // GET FOLLOWUP DOSE FROM ORDER
  getOrderMedId(order: any): string {
    // FAP1
    // const stepSavedData = this.getSavedData();
    // return stepSavedData?.dose_id;
    return order?.last_order?.dose_id
      ? order?.last_order?.dose_id
      : order?.dose_id;
  }

  // SET PRODUCT PRICE
  formatDefaultPrice(item: any) {
    const price = item.price.find((value: any) => value.default);
    const order = {
      id: item.id,
      title: item?.title,
      image: item?.image,
      doses: item?.doses,
      price: price
    };
    return order;
  }

  formatingPaymentData(
    isSales: boolean,
    promocode: any,
    old_order_id?: number
  ) {
    const stepSavedData = this.getSavedData();
    const userDate = stepSavedData?.checkout_step?.user_date;
    const userTime = stepSavedData?.checkout_step?.user_time
      ? stepSavedData.checkout_step.user_time.split('T')[1]
      : null;
    const level =
      stepSavedData.type === 4 ? this.fitnessPoints(stepSavedData.steps) : null;
    const isWeightLoss = this.isWeightLossQuiz(stepSavedData);
    const followupKickstart = old_order_id
      ? `&old_order_id=${old_order_id}`
      : '';
    const returnUrl =
      isSales && isWeightLoss
        ? `${environment.APP_ENDPOINT}${QUIZ_LINK}?current_step=100&quiz_type=${stepSavedData.type}`
        : `${environment.APP_ENDPOINT}/survey/confirmed?type=${stepSavedData.type}${followupKickstart}`;

    const isSubscription =
      stepSavedData.type === METABOLIC_QUIZ_TYPE ||
      stepSavedData.type === ANTI_AGING_QUIZ_TYPE
        ? true
        : stepSavedData?.subscription
        ? true
        : false;
    const isSurcharge = stepSavedData?.surcharge === 'on' ? true : false;
    return {
      fitness_score: level,
      dose_id: stepSavedData?.product?.price?.med_id,
      quiz_type: stepSavedData.type,
      products: [
        {
          ...stepSavedData.product,
          quantity: 1
        }
      ],
      subscription: isSubscription,
      return_url: returnUrl,
      user_date: userDate,
      user_time: userTime,
      user_id: stepSavedData.registration_step?.id,
      promocode: promocode || null,
      surcharge: isSurcharge,
      no_visit: stepSavedData?.product?.with_payment
    };
  }

  // MEMBERSHIPS
  getMemberships(filtration: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/product/getPlans`, filtration)
      .pipe(catchError(this.apiService.handleError()));
  }

  // APPOINTMENT DATE
  getAppointmentHours(date: any, interval: string): Observable<any> {
    return this.http
      .get(
        `${environment.APP_ENDPOINT}/api/checkout/getDateScheduleBusy?date=${date}&interval=${interval}`
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  // PAYMENT LINK
  getPayment(data: any): Observable<any> {
    return this.http
      .post(
        `${environment.APP_ENDPOINT}/api/checkout/createPaymentSession`,
        data
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  // SALES PAYMENT LINK
  getPaymentByToken(data: any): Observable<any> {
    return this.http
      .post(
        `${environment.APP_ENDPOINT}/api/checkout/createPaymentSessionByToken`,
        data
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  // SALES PAYMENT LINK
  getPaymentSession(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/checkout/getPaymentSession`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // GET REGISTRATION LINK BY SALES
  getRegisrtationLinkBySales(id: any): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/user/getUserQuizLink/${id}`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // SEND REGISTRATION LINK BY SALES
  sendRegisrtationLinkBySales(id: any): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/user/getUserQuizLink/${id}?email=1`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // GET REGISTRATION LINK BY USER
  getRegisrtationLink(): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/user/getUserQuizLink`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // CONFIRM PAYMENT
  checkPayment(id: string): Observable<any> {
    return this.http
      .get(
        `${environment.APP_ENDPOINT}/api/checkout/checkPaymentSession?session_id=${id}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err?.error);
        }),
        retry({ count: 10, delay: 3000 })
      );
  }

  // CONFIRM PAYMENT
  checkPaymentStatus(id: string): Observable<any> {
    let retryValue = this.getSavedData()?.checkout_step?.payment_retry || 0;
    return this.http
      .get(
        `${environment.APP_ENDPOINT}/api/checkout/checkUserPaymentComplete/${id}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.updateRetryValue();
          return throwError(() => err?.error);
        }),
        retry({ count: retryValue, delay: 10000 })
      );
  }

  updateRetryValue() {
    const savedData = this.getSavedData();
    let retryValue = savedData?.checkout_step?.payment_retry || 0;
    if (retryValue > 0) {
      retryValue -= 1;
      savedData.checkout_step.payment_retry = retryValue;
      this.localStorage.setItem(ORDER_STORAGE_KEY, JSON.stringify(savedData));
    }
  }

  // IS PAYMENT COMPLETE
  checkPaymentComplete(id: string): Observable<any> {
    return this.http.get(
      `${environment.APP_ENDPOINT}/api/checkout/checkUserPaymentComplete/${id}`
    );
  }

  // FITNESS PROGRAM
  getFitnessProgram(id: any): Observable<any> {
    return this.http
      .get(
        `${environment.APP_ENDPOINT}/api/user/getOrderFitnessScoreFile/${id}`,
        { responseType: 'blob' }
      )
      .pipe(
        map((response: any) => {
          saveAs(response, 'Fitness Program.pdf');
        }),
        catchError(this.apiService.handleError())
      );
  }

  // SEND PAYMENT LINK
  sendPaymentLink(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/auth/sendPaymentLink`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // GET USER ORDER
  getOrderById(id: any, afu?: number): Observable<any> {
    let params = new HttpParams().set('afu', afu || '');
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/user/getOrder/${id}`, {
        params: params
      })
      .pipe(catchError(this.apiService.handleError()));
  }

  // VALIDATE PROMOCODE
  validatePromocode(data: any): Observable<any> {
    return this.http
      .post(
        `${environment.APP_ENDPOINT}/api/checkout/checkPromocodeSeller`,
        data
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  // VERIFY AFFILATE PROMOCODE
  verifyPromocode(promocode: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/checkout/checkPromocode`, {
        promocode
      })
      .pipe(catchError(this.apiService.handleError()));
  }

  // CREATE PAYMENT LINK BY SALES
  createPaymentLinkBySales(data: any): Observable<any> {
    return this.http
      .post(
        `${environment.APP_ENDPOINT}/api/checkout/saveLastOrderSeller`,
        data
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  // CHECK USER SUBSCRIPTION STATUS
  userSubscriptionStatus(type: number): Observable<any> {
    return this.http
      .get(
        `${environment.APP_ENDPOINT}/api/user/checkOrderEnable?quiz_type=${type}`
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  // CHECK USER GENETIC TEST COMPLETE
  checkOrderEnableGenetic(): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/user/checkOrderEnableGenetic`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // SAVE GENETIC QUIZ
  saveOrderGenetic(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/user/saveOrderGenetic`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // QUIZ FORMATING

  getCurrentDoseType(isFollowup?: boolean): number {
    const isCurrentMedicaion = this.isWeightLossPatient();
    const stepSavedData = this.getSavedData();
    if (isCurrentMedicaion || isFollowup) {
      const selectedNextDose = stepSavedData.steps[204]?.values[0]?.id;
      if (
        selectedNextDose === 1 ||
        selectedNextDose === 2 ||
        selectedNextDose === 4
      ) {
        return stepSavedData.steps[201]?.values[0]?.doses;
      } else if (selectedNextDose === 3) {
        return stepSavedData.steps[206]?.values[0]?.doses;
      } else {
        return stepSavedData?.product?.doses;
      }
    } else {
      return stepSavedData?.product?.doses;
    }
  }

  getSelectedTierType(): string {
    const stepSavedData = this.getSavedData();
    const tierType = Object.values(stepSavedData.steps)
      .filter((item: any) => item?.values?.length)
      .map((item: any) => item.values[0])
      .find((item: any) => item?.tier_type)?.tier_type;
    return tierType || null;
  }

  getAppropriateTierDose(
    prices: PriceItem[],
    currentDose: PriceItem
  ): PriceItem | null {
    const tierType = this.getSelectedTierType();
    if (tierType === 'standard' || !currentDose.not_available || !tierType) {
      return currentDose;
    } else {
      const appropriateDose: any = prices.find(
        (item: PriceItem) => item.med_id === currentDose?.med_id
      );
      const appropriateMedId = appropriateDose[tierType];
      const appropriatePrice = prices.find(
        (item: PriceItem) => item.med_id === appropriateMedId
      );

      return appropriatePrice || null;
    }
  }

  getCurrentTakenMedId(
    prices: PriceItem[],
    followupMedId?: PriceItem
  ): DosePrice {
    const stepSavedData = this.getSavedData();
    const isCurrentMedicaion = this.isWeightLossPatient();
    let medIdPrice: DosePrice = {
      price: {
        type: 0,
        default: false,
        title: '',
        price: 0,
        quantity: 0,
        dose_volume: 0,
        price_sale: null,
        med_id: null,
        appropriate_med_id: null,
        description: null,
        interval: null,
        not_available: null,
        tier_type: null,
        tier: null,
        rapid: null,
        split: null,
        alternative: null,
        decrease_doses: null,
        increase_dose: null,
        same_dose: null
      },
      doses: 0,
      type: 0,
      nextQuantity: 0
    };
    if (isCurrentMedicaion || followupMedId) {
      const selectedNextDose = stepSavedData.steps[204]?.values[0]?.id;
      medIdPrice.type = selectedNextDose;
      if (selectedNextDose === 1) {
        console.log('Increase dose');
        const selectedProductType = stepSavedData.steps[201]?.values[0]?.doses;
        const selectedDoseStep = selectedProductType === 1 ? 202 : 203;
        const selectedDose = followupMedId
          ? followupMedId
          : stepSavedData.steps[selectedDoseStep]?.values[0];
        const nextDose = prices.find(
          (item: PriceItem) => item.med_id === selectedDose?.increase_dose
        );
        if (nextDose) {
          medIdPrice.price = this.getAppropriateTierDose(prices, nextDose);
        }
        medIdPrice.nextQuantity = nextDose?.quantity || selectedDose.quantity;
        medIdPrice.currentQuantity = selectedDose.quantity;
        medIdPrice.doses = followupMedId
          ? stepSavedData?.product?.doses
          : selectedProductType;
      } else if (selectedNextDose === 2) {
        console.log('Stay at the same dose');
        const selectedProductType = stepSavedData.steps[201]?.values[0]?.doses;
        const selectedDoseStep = selectedProductType === 1 ? 202 : 203;
        const selectedDose = followupMedId
          ? followupMedId
          : stepSavedData.steps[selectedDoseStep]?.values[0];
        if (selectedDose) {
          medIdPrice.price = this.getAppropriateTierDose(prices, selectedDose);
        }
        medIdPrice.currentQuantity = selectedDose.quantity;
        medIdPrice.nextQuantity = selectedDose.quantity;
        medIdPrice.doses = followupMedId
          ? stepSavedData?.product?.doses
          : selectedProductType;
      } else if (selectedNextDose === 4) {
        console.log('Decrease dose');
        const selectedProductType = stepSavedData.steps[201]?.values[0]?.doses;
        const selectedLowerDose = stepSavedData.steps[205]?.values[0];
        const selectedDosetypeStep = selectedProductType === 1 ? 202 : 203;
        const selectedCurrentDose = followupMedId
          ? followupMedId
          : stepSavedData.steps[selectedDosetypeStep]?.values[0];
        // [hardcode] if selected dose 1 and decrese to 0.5 then tier should be 2 - not 1
        if (
          selectedCurrentDose.quantity === 1 &&
          selectedLowerDose.quantity === 0.5
        ) {
          // [hardcodeEnd]
          medIdPrice.price = this.getAppropriateTierDose(
            prices,
            selectedCurrentDose
          );
        } else {
          medIdPrice.price = this.getAppropriateTierDose(
            prices,
            selectedLowerDose
          );
        }
        medIdPrice.currentQuantity = selectedCurrentDose.quantity;
        medIdPrice.nextQuantity = selectedLowerDose.quantity;
        medIdPrice.doses = followupMedId
          ? stepSavedData?.product?.doses
          : selectedProductType;
      } else if (selectedNextDose === 3) {
        console.log('Change medication');
        const doseProductType = this.getCurrentDoseType(
          followupMedId ? true : false
        );
        const selectedProductType = stepSavedData.steps[206]?.values[0]?.doses;
        const selectedDoseStep = selectedProductType === 1 ? 208 : 209;
        const selectedDoseToChange =
          stepSavedData.steps[selectedDoseStep]?.values[0];

        console.log(selectedDoseToChange);

        if (selectedDoseToChange) {
          medIdPrice.price = this.getAppropriateTierDose(
            prices,
            selectedDoseToChange
          );
        }
        medIdPrice.currentQuantity = selectedDoseToChange.quantity;
        medIdPrice.nextQuantity = selectedDoseToChange.quantity;
        medIdPrice.doses = selectedProductType || doseProductType;
      } else {
        medIdPrice.price = this.getAppropriateTierDose(
          prices,
          stepSavedData.product.price
        );
        medIdPrice.currentQuantity = stepSavedData.product.price?.quantity;
        medIdPrice.doses = stepSavedData.product.doses;
      }
    } else {
      medIdPrice.price = this.getAppropriateTierDose(
        prices,
        stepSavedData.product.price
      );
      medIdPrice.currentQuantity = stepSavedData.product.price?.quantity;
      medIdPrice.doses = stepSavedData.product.doses;
    }
    return medIdPrice;
  }

  selectMedicationType() {
    this.clearData();
    const dialogRef = this.dialogRef.open(StartMedicationComponent, {
      width: '805px',
      data: {
        title: 'We cannot service this state at this time',
        withoutConfirm: true,
        cancelBtn: 'Close'
      }
    });
  }

  // GET STARTED BUTTON FLOW
  public redirectToPage(link: string) {
    if (link === 'survey') {
      this.start(WEIGHTLOSS_QUIZ_TYPE);
    } else if (link === 'genetics') {
      this.start(GENETICS_QUIZ_TYPE);
    } else if (link === 'anti-aging') {
      this.start(ANTI_AGING_QUIZ_TYPE);
    } else if (link === 'metabolic') {
      this.start(METABOLIC_QUIZ_TYPE);
    } else if (link === 'ed') {
      this.startEd(ED_QUIZ_TYPE);
    } else {
      if (link.startsWith('#')) {
        const id = link.slice(1, link.length);
        this.scroller.scrollToAnchor(id);
      } else {
        this.router.navigate([link]);
      }
    }
  }

  startEd(type: number) {
    if (this.authService.isAuthenticated()) {
      this.getEdProductStart().subscribe(data => {
        if (data?.order) {
          this.router.navigate([QUIZ_LINK], {
            queryParams: {
              quiz_type: ED_FOLLOWUP_QUIZ_TYPE,
              current_step: '0',
              product_quiz_type: type,
              order_id: data.order?.id,
              with_payment: '1'
            }
          });
        } else {
          this.start(type);
        }
      });
    } else {
      this.start(type);
    }
  }

  stateIssueDialog() {
    const dialogRef = this.dialogRef.open(ConfirmDialogComponent, {
      width: '690px',
      data: {
        title: 'We cannot service this state at this time',
        withoutConfirm: true,
        cancelBtn: 'Close'
      }
    });
  }

  start(type: number) {
    this.clearData();
    const user = this.authService.userValue();
    if (user && user.state) {
      this.stateForbiddenDialog(user.state, type);
    } else {
      const dialogRef = this.dialogRef.open(CityDialogComponent, {
        width: '956px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.stateForbiddenDialog(result.id, type);
        }
      });
    }
  }

  stateForbiddenDialog(state: string, type: number) {
    const forbiddenGeneticStates = forbiddenSystemStates(type);
    if (forbiddenGeneticStates.includes(state)) {
      this.stateIssueDialog();
    } else {
      if (type === WEIGHTLOSS_QUIZ_TYPE) {
        this.startWeightLossQuiz();
      } else {
        this.startQuiz(type);
      }
    }
  }

  startQuiz(type: number) {
    this.clearData();
    const user = this.authService.userValue();
    const isLoggedIn = this.authService.isAuthenticated();
    const step =
      isLoggedIn && user?.phone_verified && user?.email_verified ? 2 : 1;
    if (isLoggedIn && !user?.quiz_start['type_' + type]) {
      this.initiateMedicationType(type)
        .pipe(
          finalize(() => {
            this.router.navigate([QUIZ_LINK], {
              queryParams: {
                quiz_type: type,
                current_step: step
              }
            });
          })
        )
        .subscribe();
    } else {
      this.router.navigate([QUIZ_LINK], {
        queryParams: {
          quiz_type: type,
          current_step: step
        }
      });
    }
  }

  // USER INITIATED QUIZ
  initiateMedicationType(type: number): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/user/startQuiz`, { type })
      .pipe(catchError(this.apiService.handleError()));
  }

  startWeightLossQuiz() {
    const user = this.authService.userValue();
    const isLoggedIn = this.authService.isAuthenticated();
    let nextStep = 18;
    if (user && user.phone_verified && user.email_verified) {
      const userQuiz = user.quizzes?.find((item: any) => item.type === 1);
      if (userQuiz) {
        const savedData = this.getSavedData();
        savedData.id = userQuiz.id;
        savedData.not_filled = userQuiz.not_filled;
        this.setData(savedData);
        if (userQuiz?.not_filled) {
          nextStep = 1;
        }
      }
    } else {
      nextStep = 1;
    }
    if (isLoggedIn && !user?.quiz_start?.type_1) {
      this.initiateMedicationType(1)
        .pipe(
          finalize(() => {
            this.router.navigate([QUIZ_LINK], {
              queryParams: {
                quiz_type: 1,
                current_step: nextStep
              }
            });
          })
        )
        .subscribe();
    } else {
      this.router.navigate([QUIZ_LINK], {
        queryParams: {
          quiz_type: 1,
          current_step: nextStep
        }
      });
    }
  }

  // ADD PRODUCT TO CARD
  addToCart(item: any, type: any) {
    if (+type === ED_QUIZ_TYPE) {
      this.startEd(ED_QUIZ_TYPE);
    } else {
      const isLoggedIn = this.authService.isAuthenticated();
      const user = this.authService.userValue();
      const isUserVerified =
        isLoggedIn && user?.phone_verified && user?.email_verified;
      let nextStep = 1;
      const stepSavedData = this.getSavedData();
      stepSavedData.product = item;
      stepSavedData.steps = {};
      stepSavedData.registration_step = {};
      stepSavedData.id = null;
      stepSavedData.user_id = null;
      this.localStorage.setItem(
        ORDER_STORAGE_KEY,
        JSON.stringify(stepSavedData)
      );
      if (isUserVerified) {
        if (+type === WEIGHTLOSS_QUIZ_TYPE) {
          nextStep = 18;
        } else {
          nextStep = 2;
        }
      }
      if (isLoggedIn && !user?.quiz_start['type_' + type]) {
        this.initiateMedicationType(+type)
          .pipe(
            finalize(() => {
              this.router.navigate([QUIZ_LINK], {
                queryParams: {
                  quiz_type: type,
                  current_step: nextStep
                }
              });
            })
          )
          .subscribe();
      } else {
        this.router.navigate([QUIZ_LINK], {
          queryParams: {
            quiz_type: type,
            current_step: nextStep
          }
        });
      }
    }
  }
}
