<app-dialog-container>
  <ng-container title>
    <h2 class="text-center">Choose your state</h2>
  </ng-container>
  <ng-container content>
    @if (!isMobilePlatform) {
    <div class="row row-xs align-items-center justify-content-center">
      @for (state of states | keyvalue : isOrder; track $index) {
      <div class="col-auto">
        <button
          class="app-button btn-full btn-toggle"
          (click)="toggle({ text: state.value.text, id: state.key })"
          [ngClass]="{ active: selectedState?.id === state.key }"
        >
          @if (state?.value && state?.value?.text) {
          <span>{{ state.value.text }}</span>
          }
          <img class="icon-toggle" src="assets/icons/check.svg" alt="" />
        </button>
        <div class="bs-xs-15"></div>
      </div>
      }
    </div>
    }@else{
    <mat-form-field appearance="fill" class="app-form-field">
      <mat-label>State</mat-label>
      <mat-select
        (selectionChange)="change($event)"
        [value]="selectedState?.id"
      >
        @for (state of states | keyvalue : isOrder; track $index) {
        <mat-option [value]="state.key">
          {{ state.value.text }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
    }
    <div class="bs-xs-10"></div>
    <div class="dialog-btn-align">
      <button class="app-button btn-lg btn-outlined" mat-dialog-close>
        Close
      </button>
      <button
        class="app-button btn-lg btn-secondary"
        [disabled]="!selectedState"
        (click)="confirm()"
      >
        Confirm
      </button>
    </div>
  </ng-container>
</app-dialog-container>
